var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-2" }, [
    _c("div", { style: { width: !_vm.noInnerWidth ? _vm.width : "auto" } }, [
      _c(
        "div",
        [
          _c("b-table", {
            attrs: {
              busy: _vm.isLoading,
              "head-variant": "light",
              "no-local-sorting": true,
              items: _vm.items,
              fields: _vm.fields,
              "per-page": "0",
              "sticky-header": _vm.height,
              "no-border-collapse": "",
              responsive: "",
              "show-empty": "",
              "empty-text": "Non ci sono informazioni da mostrare",
              small: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center my-2" },
                        [
                          _c("base-icon", {
                            attrs: {
                              name: "loading",
                              width: "35",
                              height: "35",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("Operazione in corso...")]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "cell(actions)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "action-buttons" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-1 mr-1",
                              attrs: {
                                size: "sm",
                                variant: "lisaweb",
                                title: "Modifica",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onEdit(row.item.id)
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: { icon: "pencil-square" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-1 mr-1",
                              attrs: {
                                size: "sm",
                                variant: "lisaweb",
                                title: "Elimina",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onDestroy(row.item.id)
                                },
                              },
                            },
                            [_c("b-icon", { attrs: { icon: "trash" } })],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                _vm.hasChecks && !_vm.isCheckExclusive && _vm.items.length
                  ? {
                      key: "head(check)",
                      fn: function (data) {
                        return [
                          _c(
                            "base-checkbox",
                            {
                              staticClass: "check",
                              attrs: { name: "all" },
                              on: {
                                change: function ($event) {
                                  return _vm.onSelectAllRows()
                                },
                              },
                              model: {
                                value: _vm.checkAll,
                                callback: function ($$v) {
                                  _vm.checkAll = $$v
                                },
                                expression: "checkAll",
                              },
                            },
                            [
                              data.label
                                ? _c("span", {
                                    domProps: { innerHTML: _vm._s(data.label) },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      },
                    }
                  : null,
                _vm.hasChecks
                  ? {
                      key: "cell(check)",
                      fn: function (row) {
                        return [
                          _c("base-checkbox", {
                            staticClass: "check",
                            attrs: { name: "check" },
                            on: {
                              input: function ($event) {
                                return _vm.onSelectRow(
                                  $event,
                                  row.index,
                                  row.item
                                )
                              },
                            },
                            model: {
                              value: row.item.check,
                              callback: function ($$v) {
                                _vm.$set(row.item, "check", $$v)
                              },
                              expression: "row.item.check",
                            },
                          }),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }